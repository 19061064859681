import defaultTheme from "tailwindcss/defaultTheme";
import resolveConfig from "tailwindcss/resolveConfig";
import { DefaultColors } from "tailwindcss/types/generated/colors";
import * as tailwindConfig from "../../tailwind.config";

type DefaultTailwindTheme = typeof defaultTheme;
type OurTailwindTheme = typeof tailwindConfig;

const resolvedConfig = resolveConfig(tailwindConfig);

const theme = resolvedConfig.theme as unknown as DefaultTailwindTheme & OurTailwindTheme;

export const colors = theme.colors as unknown as DefaultColors;

type Breakpoints = {
  [key: string]: number;
  "2xl": number;
  lg: number;
  md: number;
  sm: number;
  xl: number;
};

export const breakpoints: Breakpoints = Object.entries(theme?.screens || {}).reduce(
  (accumulator, [key, value]) => {
    const result = parseInt(value, 10);
    if (Number.isNaN(result)) {
      return accumulator;
    }
    return {
      ...accumulator,
      [key]: result,
    };
  },
  {} as Breakpoints
);
