import { S3FileAttachment } from "@uplift-ltd/file-uploads";
import * as yup from "yup";
import { Availability, CareerRole, Employed, Experience } from "~/__generated__";

export interface SelectOption {
  label: string;
  value: string;
}

export interface FormValues {
  availability: string;
  careerRoles: CareerRole[];
  email: string;
  employed: string;
  experience: string;
  github: string;
  location: string;
  message: string;
  name: string;
  profile: string;
  rate: string;
  referral: string;
  resume: S3FileAttachment[];
  skills: SelectOption["value"][];
  tel: string;
}

export const formLabels: Record<keyof FormValues, string> = {
  availability: "Availability",
  careerRoles: "Assessment Areas",
  email: "Email Address",
  employed: "Employment Status",
  experience: "Experience",
  github: "GitHub",
  location: "Location",
  message: "Message",
  name: "Name",
  profile: "Profile",
  rate: "Rate",
  referral: "Referral",
  resume: "Resume",
  skills: "Skills",
  tel: "Phone Number",
};

type SchemaOverrides = "resume";

export const createValidationSchema = yup
  .object()
  .shape<Record<keyof Pick<FormValues, "email" | "name">, yup.AnySchema>>({
    email: yup.string().email().required().label(formLabels.email),
    name: yup.string().required().label(formLabels.name),
  });

const updateValidationSchema = yup
  .object()
  .shape<Record<keyof Omit<FormValues, SchemaOverrides>, yup.AnySchema>>({
    availability: yup
      .string()
      .oneOf(Object.values(Availability))
      .required()
      .label(formLabels.availability),
    careerRoles: yup
      .array()
      .required()
      .of(yup.string().required().oneOf(Object.keys(CareerRole))),
    email: yup.string().email().required().label(formLabels.email),
    employed: yup
      .string()
      .when("availability", {
        is: Availability.PART_TIME,
        then: schema => schema.oneOf(Object.values(Employed)).required(),
      })
      .label(formLabels.employed),
    experience: yup
      .string()
      .oneOf(Object.values(Experience))
      .required()
      .label(formLabels.experience),
    github: yup.string().required().label(formLabels.github),
    location: yup.string().label(formLabels.location),
    message: yup.string().required().label(formLabels.message),
    name: yup.string().required().label(formLabels.name),
    profile: yup.string().required().label(formLabels.profile),
    rate: yup.string().label(formLabels.rate),
    referral: yup.string().label(formLabels.referral),
    skills: yup.array(yup.string()).min(1).required().label(formLabels.skills),
    tel: yup.string().label(formLabels.tel),
  });

const frontendOverrideSchema = yup
  .object()
  .shape<Record<keyof Pick<FormValues, SchemaOverrides>, yup.AnySchema>>({
    resume: yup
      .array()
      .of(
        yup.object().shape({
          id: yup.string().required(),
        })
      )
      .label(formLabels.resume),
  });

const backendOverrideSchema = yup
  .object()
  .shape<Record<keyof Pick<FormValues, SchemaOverrides>, yup.AnySchema>>({
    resume: yup.string().label(formLabels.resume),
  });

export const updateFrontendValidationSchema = updateValidationSchema.concat(frontendOverrideSchema);
export const updateBackendValidationSchema = updateValidationSchema.concat(backendOverrideSchema);
