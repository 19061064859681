"use client";

import { notEmpty } from "@uplift-ltd/ts-helpers";
import { debounce } from "lodash-es";
import { useEffect, useMemo, useState } from "react";

const getElementsById = (idList: string[]): HTMLElement[] => {
  return idList
    .map(id => (typeof document !== "undefined" ? document.getElementById(id) : null))
    .filter(notEmpty);
};

export const useScrollspy = (idList: string[]) => {
  const headingElements: HTMLElement[] = useMemo(() => getElementsById(idList), [idList]);
  const [activeId, setActiveId] = useState<null | string>(null);

  useEffect(() => {
    const scrollHandler = debounce(() => {
      const top = 0;
      const bottom = window.innerHeight;

      const headingVisibility = headingElements.map(headingElement => {
        const { y } = headingElement.getBoundingClientRect();
        return { visible: y > top && y < bottom, y };
      });

      // if there is any visible, use that one
      const firstVisible = headingVisibility.findIndex(({ visible }) => visible);

      if (firstVisible !== -1) {
        setActiveId(headingElements[firstVisible].id);
        return;
      }

      // if none are visible, use the one before the next one
      const firstPositive = headingVisibility.findIndex(({ y }) => y > 0);

      if (firstPositive !== -1) {
        setActiveId(headingElements[Math.max(firstPositive - 1, 0)].id);
        return;
      }

      // otherwise use the last one
      setActiveId(headingElements[headingElements.length - 1]?.id ?? null);
    }, 100);

    scrollHandler();

    window.addEventListener("scroll", scrollHandler);
    return () => window.removeEventListener("scroll", scrollHandler);
  }, [headingElements]);

  return {
    activeId,
  };
};
