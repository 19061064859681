import cx from "clsx";
import SpaceBetween from "~/components/common/SpaceBetween";

interface ChoiceRowProps {
  className?: string;
}

const ChoiceRow = ({ children, className }: React.PropsWithChildren<ChoiceRowProps>) => {
  return (
    <div className={cx("lg:flex lg:flex-row", className)}>
      <SpaceBetween spacing="mb-3 lg:mb-0 lg:mr-8">{children}</SpaceBetween>
    </div>
  );
};

export default ChoiceRow;
