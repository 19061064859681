"use client";

import cx from "clsx";
import { useMediaQuery } from "react-responsive";
import { HeadingLevel } from "~/__generated__";
import Heading from "~/components/common/Heading";
import { breakpoints } from "~/helpers/tailwind";
import { scrollToAnchorHref } from "~/helpers/urls";
import { useIsMounted } from "~/hooks/useIsMounted";
import { useScrollspy } from "./useScrollspy";

export interface MarkdownHeading {
  id: string;
  level: HeadingLevel;
  text: string;
}

interface TableOfContentsProps {
  classes?: string;
  headings?: MarkdownHeading[];
  title: string;
}

interface TableItemProps {
  href: string;
  isActive: boolean;
  text: string;
}

const TableItem = ({ href, isActive, text }: TableItemProps) => {
  const onClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    scrollToAnchorHref(e);
  };

  return (
    <li
      className={cx(
        "relative flex content-center",
        "before:absolute before:right-full before:top-1/2 before:mr-3 before:h-1 before:w-0 before:-translate-y-1/2 before:bg-orange-600 before:transition-[width] before:duration-500 before:content-['']",
        { [cx("is-active text-lg text-orange-600", "before:w-6")]: isActive }
      )}
    >
      <a
        className="w-11/12 transition-[margin-left] duration-500 group-[.is-active]:ml-8"
        href={href}
        onClick={onClick}
      >
        {text} {isActive}
      </a>
    </li>
  );
};

const TableOfContents = ({ classes, headings = [], title }: TableOfContentsProps) => {
  const isMounted = useIsMounted();
  const lg = useMediaQuery({ minWidth: breakpoints.lg });
  const headingIds = headings.map(({ id }) => id);
  const { activeId } = useScrollspy(headingIds);

  if (!isMounted || !lg) return null;

  return (
    <aside className={cx("sticky top-12 mt-8 w-3/12 px-6", classes)}>
      <Heading className="mb-4 text-2xl font-normal" tag="h3">
        {title}
      </Heading>
      <ul className="flex flex-col gap-4">
        {headings.map(({ id, text }) => (
          <TableItem href={`#${id}`} isActive={id === activeId} key={id} text={text} />
        ))}
      </ul>
    </aside>
  );
};

export default TableOfContents;
