import Alert from "~/components/common/Alert";

interface JobRoleAlertProps {
  className?: string;
}

const JobRoleAlert = ({ className }: JobRoleAlertProps) => (
  <Alert className={className} theme="info">
    While we're not actively hiring, we have a waitlist. You are welcome to apply anytime. We
    prioritize US based candidates with 6+ years of professional experience.
  </Alert>
);

export default JobRoleAlert;
