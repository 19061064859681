import ReactSelect, { GroupBase, Props as ReactSelectProps } from "react-select";

type SelectProps<
  OptionType,
  IsMulti extends boolean = false,
  GroupType extends GroupBase<OptionType> = GroupBase<OptionType>,
> = ReactSelectProps<OptionType, IsMulti, GroupType>;

function Combobox<
  OptionType,
  IsMulti extends boolean = false,
  GroupType extends GroupBase<OptionType> = GroupBase<OptionType>,
>({ styles: _unusedStyles, ...props }: SelectProps<OptionType, IsMulti, GroupType>) {
  return (
    <ReactSelect
      className="react-select"
      classNamePrefix="react-select"
      classNames={{
        // apply white in dark mode
        singleValue: () => "dark:text-white",
      }}
      instanceId={props.id}
      styles={{
        // remove color from emotion generated styles which are
        // quite difficult to override
        // We've added our own dark-mode text color above via classNames
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        singleValue({ color, ...baseStyle }) {
          return baseStyle;
        },
      }}
      {...props}
    />
  );
}

export default Combobox;
