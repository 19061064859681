import headlessUiPlugin from "@headlessui/tailwindcss";
import formsPlugin from "@tailwindcss/forms";
import typographyPlugin from "@tailwindcss/typography";
import plugin from "tailwindcss/plugin";

export const content = [
  "./content/**/*.md",
  "./content/**/*.mdx",
  "./src/**/*.ts",
  "./src/**/*.tsx",
];

export const theme = {
  colors: {
    black: "#000",
    blue: {
      100: "#ebf8ff",
      200: "#bee3f8",
      300: "#90cdf4",
      400: "#63b3ed",
      500: "#4299e1",
      600: "#3182ce",
      700: "#2b6cb0",
      800: "#2c5282",
      900: "#2a4365",
    },
    code: {
      200: "#c9d1d9",
      900: "#0d1117",
    },
    current: "currentColor",
    gray: {
      50: "#f6f6f6",
      100: "#f7fafc",
      200: "#edf2f7",
      300: "#e2e8f0",
      400: "#cbd5e0",
      500: "#a0aec0",
      600: "#718096",
      700: "#4a5568",
      800: "#2d3748",
      900: "#1a202c",
      950: "#161a22",
    },
    green: {
      100: "#f0fff4",
      200: "#c6f6d5",
      300: "#9ae6b4",
      400: "#68d391",
      500: "#48bb78",
      600: "#38a169",
      700: "#2f855a",
      800: "#276749",
      900: "#22543d",
    },
    indigo: {
      100: "#ebf4ff",
      200: "#c3dafe",
      300: "#a3bffa",
      400: "#7f9cf5",
      500: "#667eea",
      600: "#5a67d8",
      700: "#4c51bf",
      800: "#434190",
      900: "#3c366b",
    },
    "inherit-color": "inherit",
    orange: {
      100: "#fffaf0",
      150: "#fcf0e9",
      200: "#feebc8",
      300: "#fbd38d",
      400: "#f6ad55",
      500: "#ed8936",
      600: "#dd6b20",
      700: "#c05621",
      800: "#9c4221",
      900: "#7b341e",
    },
    pink: {
      100: "#fff5f7",
      200: "#fed7e2",
      300: "#fbb6ce",
      400: "#f687b3",
      500: "#ed64a6",
      600: "#d53f8c",
      700: "#b83280",
      800: "#97266d",
      900: "#702459",
    },
    purple: {
      100: "#faf5ff",
      200: "#e9d8fd",
      300: "#d6bcfa",
      400: "#b794f4",
      500: "#9f7aea",
      600: "#805ad5",
      700: "#6b46c1",
      800: "#553c9a",
      900: "#44337a",
    },
    red: {
      100: "#fff5f5",
      200: "#fed7d7",
      300: "#feb2b2",
      400: "#fc8181",
      500: "#f56565",
      600: "#e53e3e",
      700: "#c53030",
      800: "#9b2c2c",
      900: "#742a2a",
    },
    teal: {
      100: "#e6fffa",
      200: "#b2f5ea",
      300: "#81e6d9",
      400: "#4fd1c5",
      500: "#38b2ac",
      600: "#319795",
      700: "#2c7a7b",
      800: "#285e61",
      900: "#234e52",
    },
    transparent: "transparent",
    white: "#fff",
    yellow: {
      100: "#fffff0",
      200: "#fefcbf",
      300: "#faf089",
      400: "#f6e05e",
      500: "#ecc94b",
      600: "#d69e2e",
      700: "#b7791f",
      800: "#975a16",
      900: "#744210",
    },
  },
  extend: {
    animation: {
      "slide-right": "slide-right 1s ease-in-out infinite",
    },
    fontFamily: {
      sans: ["Roboto", "sans-serif"],
      serif: ["Bitter", "serif"],
    },
    fontSize: {
      "inherit-size": "inherit",
      xxs: "0.675rem",
    },
    fontWeight: {
      inherit: "inherit",
    },
    maxWidth: {
      hero: "112rem", // 1792px
      post: "48rem", // 768px
    },
    textShadow: {
      hero: "0 4px 6px rgba(0, 0, 0, 0.5)",
    },
    typography: ({ theme: twTheme }: { theme: (value: string) => string }) => ({
      DEFAULT: {
        css: {
          "--tw-prose-body": twTheme("colors.gray[900]"),
          "--tw-prose-links": twTheme("colors.blue[700]"),
        },
      },
    }),
  },
  mode: "jit",
};

export const plugins = [
  formsPlugin,
  headlessUiPlugin,
  typographyPlugin,
  plugin(({ addUtilities, addVariant, matchUtilities, theme: tailwindTheme }) => {
    addVariant("hocus", ["&:hover", "&:focus"]);
    addUtilities({
      ".up-bg-dark": {
        backgroundColor: "rgba(0, 0, 0, 0.2)",
      },
      ".up-container": {
        "@apply max-w-screen-xl mx-auto px-6": {},
      },
      ".up-container-escape": {
        "@apply -mx-6": {},
      },
      ".up-grid-cols-12": {
        "@apply grid grid-cols-1 lg:grid-cols-12 lg:gap-x-6": {},
      },
      ".up-post-card-meta-offset": {
        "@apply ml-56": {},
      },
      ".up-post-card-meta-width": {
        "@apply w-56": {},
      },
      ".up-post-container": {
        "@apply max-w-post mx-auto px-6": {},
      },
    });
    matchUtilities(
      {
        "text-shadow": value => ({
          textShadow: value,
        }),
      },
      { values: tailwindTheme("textShadow") }
    );
  }),
];
