"use client";

import { notEmpty } from "@uplift-ltd/ts-helpers";
import cx from "clsx";
import { Form } from "formik";
import { useRef, useState } from "react";
import { useUIDSeed } from "react-uid";
import {
  Availability,
  CareerRole,
  CareersContactDocument,
  ContactSubmissionFieldsFragment,
  Employed,
  Experience,
} from "~/__generated__";
import Alert from "~/components/common/Alert";
import JobRoleAlert from "~/components/common/Alert/JobRole";
import Button from "~/components/common/Button";
import { FileUpload } from "~/components/common/FileAttachments";
import ChoiceGroup from "~/components/common/Form/ChoiceGroup";
import ChoiceRow from "~/components/common/Form/ChoiceRow";
import Combobox from "~/components/common/Form/Combobox";
import FormGroup from "~/components/common/Form/FormGroup";
import FormHelpText from "~/components/common/Form/FormHelpText";
import FormStatus from "~/components/common/Form/FormStatus";
import UpliftFormik from "~/components/common/Form/Formik";
import Input from "~/components/common/Form/Input";
import Label from "~/components/common/Form/Label";
import Radio from "~/components/common/Form/Radio";
import Textarea from "~/components/common/Form/Textarea";
import Loading from "~/components/common/Loading";
import SpaceBetween from "~/components/common/SpaceBetween";
import { getRecaptchaToken } from "~/helpers/recaptcha";
import { scrollToElement } from "~/helpers/scroll";
import { setUser } from "~/helpers/sentry";
import { useContactSubmissionLoader } from "~/hooks/useContactSubmissionLoader";
import useGetUtmParams from "~/hooks/useGetUtmParams";
import { usePublicMutation } from "~/hooks/usePublicMutation";
import { useStatus } from "~/hooks/useStatus";
import { DEFAULT_CONTACT_SUBMISSION_GLOBAL_ID } from "~/server/constants/constants";
import {
  FormValues,
  SelectOption,
  createValidationSchema,
  formLabels,
  updateFrontendValidationSchema,
} from "./shared";

const skillsOptions: SelectOption[] = [
  { label: "React", value: "react" },
  { label: "React Native", value: "react-native" },
  { label: "Next.js", value: "nextjs" },
  { label: "Vue", value: "vue" },
  { label: "TypeScript", value: "typescript" },
  { label: "GraphQL", value: "graphql" },
  { label: "Apollo GraphQL", value: "apollo" },
  { label: "Python/Django", value: "python/django" },
  { label: "Python/Flask", value: "python/flask" },
  { label: "Ruby on Rails", value: "ruby-on-rails" },
  { label: "PostgreSQL", value: "postgres" },
  { label: "MySQL", value: "mysql" },
  { label: "Native iOS", value: "ios" },
  { label: "Native Android", value: "android" },
  { label: "Scala", value: "scala" },
  { label: "AWS", value: "aws" },
  { label: "Terraform", value: "terraform" },
  { label: "Selenium", value: "selenium" },
  { label: "Cucumber", value: "cucumber" },
  { label: "Continuous Integration (GitHub Actions, CircleCI, Jenkins)", value: "ci" },
  { label: "Other", value: "other" },
];

const DEFAULT_RESUME: FormValues["resume"] = [];
const DEFAULT_SKILLS: FormValues["skills"] = [];

const getInitialValues = (
  contactSubmission: ContactSubmissionFieldsFragment | null | undefined,
  careerRoles: CareerRole[] = []
): FormValues => ({
  availability: "",
  careerRoles,
  email: contactSubmission?.user.email || "",
  employed: "",
  experience: "",
  github: "",
  location: "",
  message: "",
  name: contactSubmission?.user.name || "",
  profile: "",
  rate: "",
  referral: "",
  resume: DEFAULT_RESUME,
  skills: DEFAULT_SKILLS,
  tel: "",
});

export interface CareersFormProps {
  careerRoles?: CareerRole[];
  className?: string;
  recaptchaAction?: string;
}

const CareersForm = ({ careerRoles, className, recaptchaAction = "careers" }: CareersFormProps) => {
  const { status: apiStatus, statusError } = useStatus();
  const formRef = useRef<HTMLFormElement>(null);
  const getUtmParams = useGetUtmParams();
  const seed = useUIDSeed();

  const [resumeUploading, setResumeUploading] = useState(false);
  const [contactSubmission, setContactSubmission] =
    useState<ContactSubmissionFieldsFragment | null>(null);
  const { clearContactSubmissionLoader, contactSubmissionLoading } = useContactSubmissionLoader({
    onCompleted: data => {
      if (data.me?.contactSubmission) {
        setContactSubmission(data.me?.contactSubmission);
      }
    },
  });
  const [careersContact] = usePublicMutation(CareersContactDocument);

  if (contactSubmissionLoading) return <Loading />;

  return (
    <div className={cx("flex flex-row", className)}>
      <UpliftFormik<FormValues>
        captureValuesOnError
        initialValues={getInitialValues(contactSubmission, careerRoles as CareerRole[])}
        onSubmit={async (
          values,
          { applyErrorsToFields, resetForm, setFormSuccess, setTouched, setValues }
        ) => {
          const recaptchaToken = await getRecaptchaToken(recaptchaAction);

          setUser({ email: values.email, name: values.name, tel: values.tel });
          const utmParams = getUtmParams();
          const result = await careersContact({
            variables: {
              input: {
                ...values,
                contactSubmissionId: contactSubmission?.id,
                recaptchaToken,
                resume: values.resume[0]?.id,
                url: window.location.href,
                utmParams,
              },
            },
          });

          if (result.data?.careersContact?.success) {
            scrollToElement(formRef.current);

            if (contactSubmission) {
              resetForm();
              setFormSuccess(
                "Thanks! Please check your email for a confirmation and link to take a quick assessment."
              );
              // clear the contact submission which is used to determine which fields are shown and submit behavior
              setContactSubmission(null);
              // since we don't set enableReinitialize, we need to clear the values if contactSubmissionId is set
              setValues(getInitialValues(null));
              // remove from url so if they refresh it doesn't prefill again
              clearContactSubmissionLoader();
            } else {
              setTouched({}, false); // removes initial validation errors on step 2 fields after submitting step 1
              if (result.data.careersContact.contactSubmission) {
                setContactSubmission(result.data.careersContact.contactSubmission);
              }
            }
          } else {
            applyErrorsToFields(result.data?.careersContact?.errors);
            throw new Error(
              result.data?.careersContact?.message ||
                "Something went wrong. Please try again later."
            );
          }
        }}
        resetStatusOnSubmit
        validationSchema={
          contactSubmission ? updateFrontendValidationSchema : createValidationSchema
        }
      >
        {({
          getFieldMeta,
          getFieldProps,
          isSubmitting,
          setFieldError,
          setFieldTouched,
          setFieldValue,
          status,
          values,
        }) => (
          <Form noValidate ref={formRef}>
            <JobRoleAlert />
            {(!!statusError || (apiStatus && !apiStatus.up)) && (
              <Alert theme="error">
                Looks like our email delivery service is down. Please contact us using the chat in
                the bottom right corner, or using an alternative method.
              </Alert>
            )}
            <FormStatus status={status} />
            {!contactSubmission?.submitted && (
              <SpaceBetween spacing="mb-6">
                <FormGroup>
                  <Label htmlFor={seed("name")} required>
                    {formLabels.name}
                  </Label>
                  <Input
                    id={seed("name")}
                    placeholder="Jane Doe"
                    type="text"
                    {...getFieldProps("name")}
                  />
                  <FormHelpText {...getFieldMeta("name")} />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor={seed("email")} required>
                    {formLabels.email}
                  </Label>
                  <Input
                    id={seed("email")}
                    placeholder="building@projects.com"
                    type="email"
                    {...getFieldProps("email")}
                  />
                  <FormHelpText {...getFieldMeta("email")} />
                </FormGroup>
                {contactSubmission && (
                  <SpaceBetween spacing="mb-6">
                    <FormGroup>
                      <Label htmlFor={seed("github")} required>
                        {formLabels.github}
                      </Label>
                      <Input
                        id={seed("github")}
                        placeholder="uplift-ltd"
                        {...getFieldProps("github")}
                      />
                      <FormHelpText {...getFieldMeta("github")} />
                    </FormGroup>
                    <FormGroup>
                      <Label htmlFor={seed("tel")}>{formLabels.tel}</Label>
                      <Input
                        id={seed("tel")}
                        placeholder="415-418-3420"
                        type="tel"
                        {...getFieldProps("tel")}
                      />
                      <FormHelpText {...getFieldMeta("tel")} />
                    </FormGroup>
                    <FormGroup>
                      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                      <Label required>Availability</Label>
                      <ChoiceRow>
                        <ChoiceGroup>
                          <Radio
                            id={seed("availabilityFullTime")}
                            {...getFieldProps("availability")}
                            checked={values.availability === Availability.FULL_TIME}
                            value={Availability.FULL_TIME}
                          />
                          <Label htmlFor={seed("availabilityFullTime")}>
                            Full Time (30-40h/wk)
                          </Label>
                        </ChoiceGroup>
                        <ChoiceGroup>
                          <Radio
                            id={seed("availabilityPartTime")}
                            {...getFieldProps("availability")}
                            checked={values.availability === Availability.PART_TIME}
                            value={Availability.PART_TIME}
                          />
                          <Label htmlFor={seed("availabilityPartTime")}>
                            Part Time (20-30h/wk)
                          </Label>
                        </ChoiceGroup>
                      </ChoiceRow>
                      <FormHelpText {...getFieldMeta("availability")} />
                    </FormGroup>
                    {values.availability === Availability.PART_TIME && (
                      <FormGroup>
                        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                        <Label required>Are you currently employed somewhere?</Label>
                        <ChoiceRow>
                          <ChoiceGroup>
                            <Radio
                              id={seed("employedNo")}
                              {...getFieldProps("employed")}
                              checked={values.employed === Employed.NO}
                              value={Employed.NO}
                            />
                            <Label htmlFor={seed("employedNo")}>No</Label>
                          </ChoiceGroup>
                          <ChoiceGroup>
                            <Radio
                              id={seed("employedYes")}
                              {...getFieldProps("employed")}
                              checked={values.employed === Employed.YES}
                              value={Employed.YES}
                            />
                            <Label htmlFor={seed("employedYes")}>Yes</Label>
                          </ChoiceGroup>
                        </ChoiceRow>
                        <FormHelpText {...getFieldMeta("employed")} />
                      </FormGroup>
                    )}
                    <FormGroup>
                      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                      <Label required>Professional Experience</Label>
                      <ChoiceRow>
                        <ChoiceGroup>
                          <Radio
                            id={seed("experienceJunior")}
                            {...getFieldProps("experience")}
                            checked={values.experience === Experience.JUNIOR}
                            value={Experience.JUNIOR}
                          />
                          <Label htmlFor={seed("experienceJunior")}>1-2 yrs</Label>
                        </ChoiceGroup>
                        <ChoiceGroup>
                          <Radio
                            id={seed("experienceMid")}
                            {...getFieldProps("experience")}
                            checked={values.experience === Experience.MID}
                            value={Experience.MID}
                          />
                          <Label htmlFor={seed("experienceMid")}>3-5 yrs</Label>
                        </ChoiceGroup>
                        <ChoiceGroup>
                          <Radio
                            id={seed("experienceSenior")}
                            {...getFieldProps("experience")}
                            checked={values.experience === Experience.SENIOR}
                            value={Experience.SENIOR}
                          />
                          <Label htmlFor={seed("experienceSenior")}>6+ yrs</Label>
                        </ChoiceGroup>
                      </ChoiceRow>
                      <FormHelpText {...getFieldMeta("experience")} />
                    </FormGroup>
                    <FormGroup>
                      <Label htmlFor={seed("skills")} required>
                        Skills
                      </Label>
                      <Combobox
                        id={seed("skills")}
                        {...getFieldProps("skills")}
                        blurInputOnSelect={false}
                        closeMenuOnSelect={false}
                        isMulti
                        onChange={options =>
                          setFieldValue(
                            "skills",
                            options.filter(notEmpty).map(option => option.value)
                          )
                        }
                        options={skillsOptions}
                        value={values.skills.map(
                          skill =>
                            skillsOptions.find(option => option.value === skill) as SelectOption
                        )}
                      />
                      <FormHelpText {...getFieldMeta("skills")} />
                    </FormGroup>
                    <FormGroup>
                      <Label htmlFor={seed("profile")} required>
                        Profile
                      </Label>
                      <FormHelpText className="mb-4">
                        Please link to your professional profile or resume, LinkedIn preferred.
                      </FormHelpText>
                      <Input
                        id={seed("profile")}
                        placeholder="https://www.linkedin.com/in/<example>/"
                        {...getFieldProps("profile")}
                      />
                      <FormHelpText {...getFieldMeta("profile")} />
                    </FormGroup>

                    <FormGroup>
                      <Label htmlFor={seed("resume")}>{formLabels.resume}</Label>
                      <FormHelpText className="mb-4">
                        If you want, you can upload your resume.
                      </FormHelpText>
                      <FileUpload
                        {...getFieldProps("resume")}
                        grapheneId={DEFAULT_CONTACT_SUBMISSION_GLOBAL_ID}
                        onError={err => {
                          setFieldTouched("resume", true, false);
                          setFieldError("resume", err.message);
                        }}
                        onLoading={setResumeUploading}
                        recaptchaAction="resume"
                      />
                      <FormHelpText {...getFieldMeta("resume")} />
                    </FormGroup>

                    <FormGroup>
                      <Label htmlFor={seed("rate")}>Rate Range</Label>
                      <FormHelpText className="mb-4">
                        How much do you want to get paid per hour or per year?
                      </FormHelpText>
                      <Input
                        id={seed("rate")}
                        placeholder="ex. $X-$Y per hour. Or $ABC per year"
                        {...getFieldProps("rate")}
                      />
                      <FormHelpText {...getFieldMeta("rate")} />
                    </FormGroup>
                    <FormGroup>
                      <Label htmlFor={seed("tel")}>Referral</Label>
                      <FormHelpText className="mb-4">How did you hear about us?</FormHelpText>
                      <Input
                        id={seed("referral")}
                        placeholder="ex. Hacker News, Indeed, a person's name/email"
                        {...getFieldProps("referral")}
                      />
                      <FormHelpText {...getFieldMeta("referral")} />
                    </FormGroup>
                    <FormGroup>
                      <Label htmlFor={seed("location")}>Location</Label>
                      <FormHelpText className="mb-4">Where do you usually work from?</FormHelpText>
                      <Input
                        id={seed("location")}
                        placeholder="ex. Boulder, CO"
                        {...getFieldProps("location")}
                      />
                      <FormHelpText {...getFieldMeta("location")} />
                    </FormGroup>
                    <FormGroup className="mb-6">
                      <Label htmlFor={seed("message")} required>
                        Message
                      </Label>
                      <FormHelpText className="mb-4">
                        Include things like: how many years you've worked with a skill, have you
                        taken applications to production and written production code, requirements
                        gathering, estimates, what kind of work are you enjoying the most lately,
                        what excites you about the opportunity, what do you hope to learn.
                      </FormHelpText>
                      <Textarea
                        id={seed("message")}
                        {...getFieldProps("message")}
                        required
                        rows={8}
                      />
                      <FormHelpText {...getFieldMeta("message")} />
                    </FormGroup>
                  </SpaceBetween>
                )}

                <Button disabled={isSubmitting || resumeUploading} type="submit">
                  {contactSubmission ? "Submit Application" : "Next"}
                </Button>
              </SpaceBetween>
            )}
          </Form>
        )}
      </UpliftFormik>
    </div>
  );
};

export default CareersForm;
