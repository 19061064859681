import cx from "clsx";
import SpaceBetween from "~/components/common/SpaceBetween";

interface ChoiceGroupProps {
  className?: string;
}

const ChoiceGroup = ({ children, className }: React.PropsWithChildren<ChoiceGroupProps>) => {
  return (
    <div className={cx("group/choice flex flex-row", className)}>
      <SpaceBetween spacing="mr-2">{children}</SpaceBetween>
    </div>
  );
};

export default ChoiceGroup;
