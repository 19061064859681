import cx from "clsx";
import React from "react";
import { formStyles } from "./Form.styles";

type InputElementProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

export type RadioProps = Pick<
  InputElementProps,
  | "checked"
  | "className"
  | "defaultChecked"
  | "disabled"
  | "id"
  | "name"
  | "onBlur"
  | "onChange"
  | "placeholder"
  | "required"
  | "type"
  | "value"
>;

const Radio = React.forwardRef<HTMLInputElement, RadioProps>(
  ({ className, ...otherProps }, ref) => {
    return (
      <input className={cx(formStyles.radio, className)} ref={ref} type="radio" {...otherProps} />
    );
  }
);

Radio.displayName = "Radio";

export default Radio;
